<template>
  <div class="shejihd_c">
    <div class="shejihd_ctopbox">
      <div class="shejihd_ctopbox_l">
        <div class="shejihd_ctopbox_l_title">
          <p>{{ data.title }}</p>
        </div>
        <div
          class="shejihd_ctopbox_l_4l"
          style="line-height: normal; height: 2rem"
        >
          报名截止时间：{{ data.deadline }}
        </div>
        <div class="shejihd_ctopbox_l_4l">主办单位：{{ data.host_unit }}</div>
        <div class="shejihd_ctopbox_l_4l" v-if="data.a_shape == 2">
          活动地点：{{ data.address == "" ? "暂无地址" : data.address }}
        </div>
        <div class="shejihd_ctopbox_l_4l">
          活动时间：{{ data.draw_start }} ~ {{ data.draw_end }}
        </div>
        <div class="shejihd_ctopbox_l_4l">
          活动网址：{{ data.activity_url }}
        </div>
        <div class="shejihd_ctopbox_l_4l">
          参与方式：{{
            data.way == "1"
              ? "报名参与"
              : data.way == "2"
              ? "购票参与"
              : "直接参与"
          }}
        </div>
        <div class="shejihd_ctopbox_l_4l">
          活动费用：{{ data.money > 0 ? "￥" + data.money + "" : "免费" }}
        </div>
      </div>
      <div class="shejihd_ctopbox_r">
        <div class="shejihdulli_timetype" style="right: 0">
          <img
            v-if="data.draw_zt == 1"
            src="https://task.hozoin.cn/Works/20210105/sj-0625411431.png"
            alt=""
          />
          <img
            v-if="data.draw_zt != 1"
            src="https://task.hozoin.cn/Works/20210105/sj-0843395723.png"
            alt=""
          />
          <span>{{ data.draw_zt == 1 ? "进行中" : "已结束" }}</span>
        </div>
        <div
          v-if="bmbtntype"
          class="shejihd_ctopbox_rcanyu"
          @click="canyubtn(data.activity_url)"
          :class="canyubtnbk"
        >
          {{ bmtypenum }}
        </div>
      </div>
    </div>
    <div class="shejihd_ccenter" v-html="data.drawtext"></div>
    <Sjpinglun :all="pinglunall" />
    <!-- <zan :all="zanall" /> -->
    <div class="hdneiye_qtbox">
      <div class="hdneiye_qt">其他活动</div>
      <ul class="shejihdul">
        <li
          class="shejihdulli"
          v-for="(item, index) in shejihdulall"
          :key="index"
          @click="shejihdulliclick(item.a_id)"
        >
          <div class="shejihdulli_img">
            <img class="shejihdulli_imgimg" :src="item.title_pic" alt="" />
            <div class="shejihdulli_imgtype">专题活动</div>
            <div
              class="shejihdulli_money"
              v-if="item.pay_state == 0 ? false : true"
            >
              <div class="shejihdulli_moneytype">收费</div>
            </div>
          </div>
          <div class="shejihdulli_title">
            <div>{{ item.a_type == 1 ? "线上活动" : "线下活动" }}</div>
            <p>{{ item.title }}</p>
          </div>
          <div class="shejihdulli_time">
            <p class="shejihdulli_timep">
              {{ item.draw_start }} ~ {{ item.draw_end }}
            </p>

            <div class="shejihdulli_timetype">
              <img
                v-if="item.draw_zt == 1"
                src="https://task.hozoin.cn/Works/20210105/sj-0625411431.png"
                alt=""
              />
              <img
                v-if="item.draw_zt != 1"
                src="https://task.hozoin.cn/Works/20210105/sj-0843395723.png"
                alt=""
              />
              <span>{{ item.draw_zt == 1 ? "进行中" : "已结束" }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <el-dialog
      title="立即参加活动"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <div>
        <el-input v-model="contact" placeholder="请输入参加理由"></el-input>
        <el-input
          class="hdbaominginp"
          v-model="phone"
          placeholder="请输入联系方式"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="canjiabtn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import zan from "../components/zan";
import Sjpinglun from "../components/Sjpinglun";
export default {
  components: {
    zan,
    Sjpinglun,
  },
  data() {
    return {
      bmbtntype: false,
      canyubtnbk: "",
      dialogVisible: false,
      contact: "",
      phone: "",
      hdid: 0,
      pinglunall: {
        classid: "4",
        id: "",
      },
      data: {},
      shejihdulall: [],
      userinfo: {},
      zanall: {
        classid: "4",
        id: "0",
      },
      bmtypenum: "",
    };
  },
  methods: {
    shejihdulliclick(id) {
      this.$router.push({ path: "/shejihd_c", query: { data: id } });
      this.$router.go(0);
    },
    canyubtn(url) {
      console.log(url);
      var that = this;
      if (that.data.way == "1") {
        //报名参与
        if (this.userinfo != null) {
          if (this.bmtypenum == "报名成功") {
            this.$message({
              message: "已报名，不可重复报名",
              duration: 1000,
            });
          } else {
            this.dialogVisible = true;
          }
        } else {
          this.$router.push({ path: "/sjlogin" });
        }
      } else {
        //跳转外链
        var u = "http://" + url;
        window.open(u);
      }
    },
    canjiabtn() {
      var that = this;
      if(that.contact==''){
        this.$message({
            message: "请填写参加理由",
            duration:3000,
          });
        return false;
        
      }
      if(that.phone==''){
        this.$message({
            message: "请填写联系方式",
            duration:3000,
          });
        return false;
      }
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/myEcmsActivityCanjia",
        method: "post",
        data: {
          a_id: that.hdid,
          contact: that.contact,
          phone: that.phone,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.dialogVisible = false;
          this.$message({
            message: "报名成功",
            duration:3000,
          });

          location.reload();
        } else {
        }
      });
    },
    bmtype() {
      var that = this;
      if (that.userinfo != null) {
        this.$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/myFindActivity",
          method: "post",
          data: {
            a_id: that.hdid,
          },
        }).then((res) => {
          if (res.data.code == 1) {
            that.canyubtnbk = "canyubtnbk";
            that.bmtypenum = "报名成功";
          } else {
            if (that.data.way == "1") {
              that.bmtypenum = "立即报名";
            } else {
              that.bmtypenum = "立即前往";
            }
          }
        });
      } else {
        that.bmtypenum = "立即报名";
      }
    },
  },
  created() {
    var that = this;
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    var id = that.$route.query.data;
    that.hdid = id;
    that.zanall.id = id;
    that.pinglunall.id = id;
    this.$axios({
      url: "/api/getActivityInfo",
      method: "post",
      data: {
        id,
      },
    }).then((res) => {
      if (res.data.code == 1) {
        that.data = res.data.result;
        document.title = res.data.result.title;
        this.fenxiang(
          res.data.result.title,
          res.data.result.title,
          res.data.result.title_pic
        );
        if (res.data.result.draw_zt == "1") {
          that.bmbtntype = true;
          that.bmtype();
        } else {
          that.bmbtntype = false;
        }
      } else {
        console.log("请求失败");
      }
    });
    this.$axios({
      url: "/api/getActivityList",
      method: "post",
      data: {
        a_type: "",
        page: "1",
        limit: "4",
      },
    }).then((res) => {
      if (res.data.code == 1) {
        that.shejihdulall = res.data.result.list;
      } else {
        console.log("请求失败");
      }
    });
  },
};
</script>
<style>
@import "../assets/css/shejihd.css";
</style>